.home-cards {
    .col-md-4 {
        @include mobile {
            margin-bottom: 15px;
        }

    }
}

.card {
    border-radius: 10px;
    // background-color: $yash;
    text-align: center;
    height: 100%;
    box-shadow: 2px 2px 6px 1px #e5e3e3;
    overflow: hidden;
    transition: all 1s;

    @include mobile {
        margin-bottom: 20px;
        height: auto;

    }

    &:hover {
        img {

            @include desktop {
                scale: (1.2);
                transition: all 1s;
            }
        }
    }

    img {
        width: 100%;
        // max-width: 160px;
        transition: all 1s;
    }

    .card-body {
        background-color: $white;
        border-bottom: 1px solid #e5e3e3;


        p {
            max-height: 180px;
            overflow: hidden;
            margin-bottom: 0
        }

        h4 {
            color: $blue;
        }

    }

    .btn-wrapper {
        margin: 15px auto;
    }


}