.watsapp-icon {
    position: fixed;
    bottom: 15%;
    right: 0;
    z-index: 5;

    @include mobile {
        top:  auto;
        bottom: 50px;
        right: 15px;
    }
    

    img {
        width: auto;
        max-width: 80px;
        -webkit-animation:spin 4s linear infinite;
        -moz-animation:spin 4s linear infinite;
        animation:spin 4s linear infinite;

        @include mobile {
            max-width: 50px;
        }
    }
    
        
    }

    @-moz-keyframes spin { 
        0% { -moz-transform: rotate(0deg); } 
        50% { -moz-transform: rotate(-45deg); } 
        100% { -moz-transform: rotate(0deg); } 
    }
    @-webkit-keyframes spin { 
        0% { -webkit-transform: rotate(0deg); } 
        50% { -webkit-transform: rotate(-45deg); } 
        100% { -webkit-transform: rotate(0deg); } 
    }
    @keyframes spin { 
        0% { 
            -webkit-transform: rotate(0deg); 
            -webkit-transform: rotate(0deg); 
        } 
        50% { 
            -webkit-transform: rotate(-45deg); 
            -webkit-transform: rotate(-45deg); 
        } 
        0% { 
            -webkit-transform: rotate(0deg); 
            -webkit-transform: rotate(0deg); 
        } 
    }
