.enquiryForm {
    background-color: $light-blue;
    padding: 50px 25px 50px 25px;
    margin-bottom: 0;

    @include mobile {
        padding: 30px 15px 30px 15px;
        margin-bottom: 30px;
    }

    img {
        width: 100%;
    }

    .col-md-4 {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.form-wrapper {
    background-color: $white;
    padding: 35px 25px;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
}

.btn-wrap {
    text-align: right;
}

.form-group {
    span {
        color: $error;
    }
}

.form-control {
    border: none;
    background: #f9f9f9;
    box-shadow: inset 0px 1px 2px 0px rgba(0, 0, 0, 0.15);
    border-radius: 5px;

    &:focus {
        box-shadow: none;
        border: 1px solid #05B6FF;
    }

    &.error {
        border:1px solid $error;
        box-shadow: none;
        background-color: $white;
    }

    &.sucess {
        border:1px solid $green;
        box-shadow: none;
        background-color: $white;
    }



}

.form-select {
    display: block;
    border: none;
    background: #f9f9f9;
    box-shadow: inset 0px 1px 2px 0px rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    min-height: 38px;
    width: 100%;

    &:focus-visible {
        outline: none;
    }
}



::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #D9D9D9 !important;
}

::-moz-placeholder {
    /* Firefox 19+ */
    color: #D9D9D9 !important;
}

:-ms-input-placeholder {
    /* IE 10+ */
    color: #D9D9D9 !important;
}

:-moz-placeholder {
    /* Firefox 18- */
    color: #D9D9D9 !important;
}